export const HOME = {
  role: `Assistant Professor of Political Science`,
  college: `University of Maryland, Baltimore County`,
  intro: `I am an Assistant Professor of Political Science at the University of Maryland,
  Baltimore County. My research explores identity, race, psychology, and behavior, focusing on
  how Black people overcome disillusionment and maintain their commitment to democracy.
  Before joining UMBC, I was a predoctoral associate of research at Duke University and served
  as a head teaching assistant for the Ralph Bunche Summer Institute. You can find my work
  published in journals such as Advances in Political Psychology.`,
  publications: `Research`,
  publicationsList: [
    {
      date: " Advances in Political Psychology 2022",
      title: "The Role of Race in Political Attitudes among the Non-Religious",
      subtitle: "with Janelle Wong",
      path: "https://onlinelibrary.wiley.com/doi/10.1111/pops.12849",
      download: false,
    },
    {
      date: "Working Papers",
      title: "Black Racial Grievance and Black American Identity",
    },
    {
      date: "Working Papers",
      title: "Operationalizing Black Racial Grievance for Political Behavior",
    },
  ],
};
