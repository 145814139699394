export const ABOUT = {
  intro: `I am an Assistant Professor of Political Science at the University of Maryland,
  Baltimore County. My research explores identity, race, psychology, and behavior, focusing on
  how Black people overcome disillusionment and maintain their commitment to democracy.
  Before joining UMBC, I was a predoctoral associate of research at Duke University and served
  as a head teaching assistant for the Ralph Bunche Summer Institute. You can find my work
  published in journals such as Advances in Political Psychology.`,
  text: `My dissertation, titled "Red, White, and Black: Black Racial Grievance and Black American Identity," delves into the intricate relationship between individuals' psychological responses to racism and the subsequent influence on their behaviors, attitudes, and overall Black American identity. Through this exploration, I aim to contribute valuable insights into the complexities of being both Black and American.`,
  text_2: `In collaboration with Janelle Wong, our published work in Advances in Political Psychology addresses the influence of race on political attitudes within the religiously unaffiliated group. Through our research, we illuminate the underlying factors contributing to the rise of nonreligious individuals in the U.S. Furthermore, our study delves into the complex landscape of racial disparities in stances on crucial issues like same-sex marriage, immigration, and taxes, uncovering insights that have far-reaching implications for understanding contemporary political dynamics.`,
  text_3: `My research has garnered strong support through several prestigious grants and awards. In recognition of the potential impact of my work, I received a $2,500 grant from the College of Behavioral and Social Sciences during the summer of 2019. The significance of my research was further underscored in 2020 with a $10,000 grant from the University of Maryland, College Park, in collaboration with my co-advisor, Lilliana Mason. Additionally, I was honored to be awarded the Baumann Fellowship for the years 2021-2023, where I contribute as a research assistant under the guidance of my co-advisor, Chryl Laird. This fellowship was accompanied by a $10,000 grant from the College of Behavioral and Social Sciences, reinforcing the importance of my research contributions. Finally, Duke University provided me with $4,000 in predoctoral research funding.`,
  contact: `If you wish to contact me, please feel free to reach out via email at  j.stsume [at] duke dot edu.`,
};
